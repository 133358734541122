"use client";
import React, { useMemo, useCallback, useContext, useEffect, useState } from "react";

import { AlertNetwork } from "../components/AlertNetwork";
import { PERSONAL_CHANNEL_KEY } from "../components/channels/common";
import { useBackend } from "../hooks/useBackend";
import { useIsPersonalUser } from "../hooks/useIsPersonalUser";
import type { OrgMember, Team } from "../models";
import { useOrganization } from "./OrganizationControlledContext";
import { UserContext } from "./UserContext";

export type TeamState = {
  members?: OrgMember[];
  rootTeam?: Team;
  /** 初期レンダリング時から、企業情報が設定次第チームデータを読み込むかどうか */
  isRequiredAtFirst?: boolean;
  teamMap: TeamMap | undefined;
};

type TeamDispatch = {
  mutateTeamState: (names: Array<"rootTeam" | "members">) => Promise<Array<void | Team | OrgMember[] | undefined>>;
  requireTeamState: () => void;
};

const initialState: TeamState = {
  members: undefined,
  rootTeam: undefined,
  isRequiredAtFirst: undefined,
  teamMap: undefined,
};

// context (State)
const TeamStateContext = React.createContext<TeamState>(initialState);

// context (Dispatch)
const SetTeamStateContext = React.createContext<TeamDispatch>({
  mutateTeamState: () => Promise.resolve([undefined]),
  requireTeamState: () => null,
});

type TeamMap = { [key in string]: Team };

/**
 * チーム(Team)のflatten内部処理実装
 * 引数に与えられたチーム一覧の childrenフィールドを抜き出し、連想配列に貯める
 * @param list flatten対象のチーム一覧
 * @param map チームIDをkeyとした処理済みのチーム(連想配列)
 * @returns map 全て処理し終わったチーム(連想配列)
 */
function flattenTeams(list: Team[], map: Record<string, Team> = {}): TeamMap {
  if (list.length === 0) return map;
  // TODO(kuri): forEachじゃなくmapで対応したいが、Team[][]になることを回避する必要がある
  // => 時間が無いので今は妥協
  const next: Team[] = [];
  list.forEach((t) => {
    if (!map[t.id]) map[t.id] = t;
    if (!t.children) return false;
    if (Array.isArray(t.children)) {
      next.push(...t.children);
    } else {
      next.push(t.children);
    }
  });
  return flattenTeams(next, map);
}

export function TeamProvider(props: { initialState?: TeamState; children: React.ReactNode }) {
  const {
    userState: { user },
  } = useContext(UserContext);
  const org = useOrganization();
  const isPersonalUser = useIsPersonalUser();

  // ※ `org` が falsy で、isFetchStateが false ならば `rootTeam` & `members` は undefinedになる
  // 実際に値を利用するタイミングになってからデータフェッチさせるためのローカルステート
  const [isRequiredState, setIsRequiredState] = useState(initialState.isRequiredAtFirst ?? false);
  const requireTeamState = useCallback(() => setIsRequiredState(true), []);

  const {
    data: rootTeam,
    error: rootTeamError,
    mutate: mutateRootTeam,
  } = useBackend<Team>(user && org && !isPersonalUser && isRequiredState && `/teams/${org.id}`, {
    revalidateIfStale: false,
    revalidateOnReconnect: false,
  });

  // 所属メンバーは大量になることもあるためContextで管理させる
  const {
    data: members,
    error: membersError,
    mutate: mutateMembers,
  } = useBackend<OrgMember[]>(
    user && org && !isPersonalUser && isRequiredState && `/teams/${org.id}/members?extended_profile=true`,
    {
      revalidateIfStale: false,
      revalidateOnReconnect: false,
    }
  );

  /** 木構造のrootTeamをTeam["id"]をキーとした連想配列に展開したものをメモ化 */
  const teamMap = useMemo(() => {
    const arr = !rootTeam ? rootTeam : flattenTeams([rootTeam]);
    return arr;
  }, [rootTeam]);

  // 引数に渡した "rootTeam" | "members" の配列から必要なコンテキストの値を再取得する
  const mutateTeamState = useCallback(
    (names: Array<"rootTeam" | "members">) => {
      if (names.includes("members")) requireTeamState();
      const arr = Array.from(new Set(names));
      return Promise.all(
        arr.map((name) =>
          name === "rootTeam"
            ? mutateRootTeam().then((res) => res)
            : name === "members"
              ? mutateMembers().then((res) => res)
              : Promise.resolve()
        )
      );
    },
    [mutateRootTeam, mutateMembers, requireTeamState]
  );

  return (
    <TeamStateContext.Provider value={{ rootTeam, members, teamMap }}>
      <SetTeamStateContext.Provider value={{ mutateTeamState, requireTeamState }}>
        {props.children}
      </SetTeamStateContext.Provider>
      <AlertNetwork error={Boolean(rootTeamError) || Boolean(membersError)} title="チーム情報" />
    </TeamStateContext.Provider>
  );
}

function useTeamState() {
  return useContext(TeamStateContext);
}
function useSetTeamState() {
  return useContext(SetTeamStateContext);
}

/**
 * 企業情報に紐づく、rootTeam情報を取得する
 * rootTeam(Team)には `children` があり、チーム構成が階層化されている
 * @returns Team
 */
export const useRootTeam = () => {
  const state = useTeamState();
  const { requireTeamState } = useSetTeamState();
  useEffect(() => {
    requireTeamState();
  }, [requireTeamState]);
  return state.rootTeam;
};

/**
 * 企業に所属するメンバー(OrgMember)を一覧で取得する
 * @returns OrgMember[]
 */
export const useTeamMembersAll = () => {
  const { requireTeamState } = useSetTeamState();
  const state = useTeamState();

  useEffect(() => {
    requireTeamState();
  }, [requireTeamState]);

  return state.members;
};

/**
 * Contextの変数情報を再取得する為の関数を提供する
 * @returns
 */
export function useTeamStateMutate() {
  const state = useSetTeamState();
  return state.mutateTeamState;
}

/**
 * 企業に所属するメンバーをID指定で検索する
 * @param userId 指定するユーザーID
 * @returns メンバー情報 (OrganizationMember)
 */
export const useTeamMember = (userId?: string) => {
  const { requireTeamState } = useSetTeamState();
  const { members } = useTeamState();

  useEffect(() => {
    requireTeamState();
  }, [requireTeamState]);

  return !userId || !members
    ? undefined
    : members
        .filter((v) => v.id === userId)
        .reduce((prev: OrgMember | undefined, current: OrgMember | undefined) => current ?? prev, undefined);
};

export const useMemoedTeamMapOptional = () => {
  const ctx = useTeamState();
  return ctx.teamMap;
};
export const useMemoedTeamMap = () => {
  const res = useMemoedTeamMapOptional();
  return res ?? ({} as TeamMap);
};

/**
 * 木構造になっているチーム構成を一次配列化する
 * @param rootTeam チームをflattenさせる基点となるチーム情報(Team)
 * @param includeRoot 引数rootTeamも返却するチーム一覧に追加するかどうか
 * @returns チーム一覧(Team[])
 */
export function useFlatTeams(rootTeam?: Team, includeRoot = false): Team[] | undefined {
  if (!rootTeam) return undefined;
  if (!rootTeam.children) return includeRoot ? [rootTeam] : [];
  if (rootTeam.category !== "organization") throw new Error("invalid rootTeam category");
  const teams = includeRoot ? [rootTeam] : [...rootTeam.children];
  const mapResult = flattenTeams(teams);
  // 処理が重いかも (Object.values)
  return Object.values(mapResult);
}

/**
 * useRootTeam + useFlatTeams のエイリアス
 * よく利用するため一つのHooksとしてまとめている
 */
export const useFlatTeamAll = (includeRoot = false) => {
  const root = useRootTeam();
  const teams = useFlatTeams(root, includeRoot);
  return teams;
};

export const useGetTeamName = (
  options: Partial<{ isFormal: boolean; placeholder: string }> = {
    isFormal: false,
    placeholder: "不明",
  }
): ((id?: string | undefined) => string) => {
  const t404 = options.placeholder ?? "不明";
  const list = useFlatTeamAll(true);
  return useCallback(
    (id?: string | undefined) => {
      if (id === undefined) {
        return t404;
      } else if (id === PERSONAL_CHANNEL_KEY) {
        return "個人用";
      } else {
        const result = list?.find((v) => {
          const targets = [v.id, `team${v.id}`];
          const t = targets.includes(id);
          return t;
        });
        if (!result) {
          return t404;
        } else if (options.isFormal) {
          return result.formal_name;
        } else {
          return result.name;
        }
      }
    },
    [options, list, t404]
  );
};
