import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import axiosRetry from "axios-retry";
import { User } from "firebase/auth";

import { RimoBackendUrl, RimoImageServerUrl } from "../constants/services";
import Sentry from "../utils/sentry";
import { isDev } from "../utils/system";
import { getAccessToken } from "./token";

import { isLanguageCode } from "@rimo/i18n/settings";
import { AXIOS_INSTANCE, isStorybook } from "./customInstance";

export type RimoHttpRequestConfig = AxiosRequestConfig<{
  // firebaseからのjwtトークンを強制的に洗い替える判定が可能
  // ※ 今後、同一の要素をbackendで利用することになった際に自動的にプロパティ削除が実行されることに注意
  // e.g. libs/frontend/src/api/voice.ts
  rimoForceRefreshToken?: boolean | undefined;
  allowAnonymous?: boolean | undefined;
}>;

export const instance = axios.create({
  baseURL: RimoBackendUrl,
  timeout: 120000, // Microsoft Teams 関連の API が重いため一時的にタイムアウトを延長
  headers: {
    "Content-Type": "application/json",
  },
  responseType: "json",
});

export const client = isStorybook ? AXIOS_INSTANCE : instance;

export const imageServerClient = axios.create({
  baseURL: RimoImageServerUrl,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
  },
  responseType: "json",
});

let localeInterceptorId: number | null = null;
let authorizationInterceptorId: number | null = null;

export const setupLocaleAxiosRequestInterceptor = (locale: string, instance = client) => {
  if (!isLanguageCode(locale)) return;

  if (localeInterceptorId !== null) {
    instance.interceptors.request.eject(localeInterceptorId);
  }

  localeInterceptorId = instance.interceptors.request.use((config) => {
    config.headers.set("Accept-Language", locale);
    return config;
  });
};

export const setupAxiosRequestInterceptor = (user: User, instance = client) => {
  if (authorizationInterceptorId !== null) {
    instance.interceptors.request.eject(authorizationInterceptorId);
  }

  authorizationInterceptorId = instance.interceptors.request.use(async (config) => {
    // Authorizationヘッダが呼び出し側で付与された場合は、その値を利用する
    if (config.headers?.get("Authorization")) {
      return config;
    } else {
      config.headers.set("Authorization", `Bearer ${await getAccessToken(config, user)}`);
      return config;
    }
  });
};

let interviewIdInterceptorId: number | null = null;

export const setupInterviewerIdInterceptor = (interviewerId: string, interviewContentId: string) => {
  if (interviewIdInterceptorId !== null) {
    client.interceptors.request.eject(interviewIdInterceptorId);
  }

  interviewIdInterceptorId = client.interceptors.request.use((config) => {
    config.headers.set("Interviewer-ID", interviewerId);
    config.headers.set("Interview-Content-ID", interviewContentId);
    return config;
  });
};

export const clearInterviewerIdInterceptor = () => {
  if (interviewIdInterceptorId !== null) {
    client.interceptors.request.eject(interviewIdInterceptorId);
    interviewIdInterceptorId = null;
  }
};

export const setupAxiosResponseInterceptor = (instance = client) => {
  client.interceptors.response.clear();
  addAxiosRetryInterceptor(instance);
  addAxiosErrorInterceptor(instance);
};

export const clearAllAxiosInterceptors = (instance = client) => {
  instance.interceptors.request.clear();
  instance.interceptors.response.clear();
};

const parseBackendErrorResponse = (responseData: unknown) => {
  try {
    if (typeof responseData === "string") {
      // パースできない文字列は例外になる
      return JSON.parse(responseData);
    } else {
      // JSON化させる必要がないものはそのまま返却する
      return responseData;
    }
  } catch (_) {
    // パースに失敗した場合は、引数をそのまま返却する
    return responseData;
  }
};

const isRetryableAxiosError = (err: Error) => {
  return (
    axios.isAxiosError(err) &&
    (axiosRetry.isRetryableError(err) ||
      axiosRetry.isNetworkError(err) ||
      (err.response && err.response.status >= 500 && err.response.status <= 599))
  );
};

const addAxiosRetryInterceptor = (axiosInstance: AxiosInstance) => {
  axiosRetry(axiosInstance, {
    retries: 3,
    retryDelay: axiosRetry.exponentialDelay,
    retryCondition(err) {
      // リクエストのキャンセル時
      // サーバからコネクションを切断された時
      if (!err.code || err.code === "ECONNABORTED") return false;

      // リトライできるケース
      if (isRetryableAxiosError(err)) return true;

      // リトライ不可時はリトライしない
      return false;
    },
    onRetry(retryCount, err, requestConfig) {
      console.warn("Retrying API request Count:", retryCount, err, requestConfig);
      // 最大回数再試行が失敗した場合はエラーとして扱う
      Sentry.captureException(err, {
        contexts: {
          apiRetry: {
            retryCount,
            requestConfig,
            code: err.code,
            message: err.message,
            name: err.name,
            stack: err.stack,
            status: err.status,
          },
        },
      });
    },
  });
};

const addAxiosErrorInterceptor = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.response.use(
    // 開発時はAPIレスポンスを情報として表示
    !isDev
      ? (r) => r
      : (r) => {
          console.debug(`[Axios][${r.status}][${r.config.method?.toUpperCase()}] ${r.config.url}`, r.data);
          return r;
        },
    (err) => {
      if (isRetryableAxiosError(err)) {
        // noop of retryable errors
      } else if (axios.isAxiosError(err)) {
        // リトライ以外のAxiosのエラーを表示する
        const errorBody = err.response?.data ? JSON.stringify(err.response?.data) : null;
        const errorMessage = `[Axios][${err.response?.status}][${err.config?.method?.toUpperCase()}] ${
          err.config?.url
        } ${errorBody}`;
        const errorParams = {
          code: err.code,
          message: err.message,
          responseData: parseBackendErrorResponse(err.response?.data),
        };

        if (err.response && err.response.status < 500) {
          console.warn(errorMessage, errorParams);
        } else {
          console.warn(errorMessage, errorParams);
        }
      } else if (err instanceof Error) {
        console.warn("[API][Error]", err.name, err.message);
      } else {
        console.warn("[API][Unknown]", err);
      }

      throw err;
    }
  );
};
