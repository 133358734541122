"use client";

import React from "react";

import { getAuth, setPersistence, browserLocalPersistence, browserSessionPersistence } from "firebase/auth";

import { useOrganizationId } from "../hooks/useOrganizationId";
import { Organization, Support, TeamPlan } from "../models";
import { useOrganizations } from "./OrganizationContext";

type State = {
  data: undefined | Organization;
};
type Dispatch = {
  reset: () => void;
  activate: (o: Organization | null) => void;
  revalidate: () => void;
};

const OrganizationControlledStateContext = React.createContext<State>({
  data: undefined,
});

const OrganizationControlledDispatchContext = React.createContext<Dispatch>({
  activate: () => null,
  reset: () => null,
  revalidate: () => null,
});

export const AdminOrganizationControlProvider: React.FC<React.PropsWithChildren<{ id: string }>> = (props) => {
  return <OrganizationControlledProvider {...props} disabledPersistence />;
};

export const OrganizationControlledProvider: React.FC<
  React.PropsWithChildren<
    Partial<{
      initialState: State;
      id: string;
      disabledPersistence: boolean;
    }>
  >
> = (props) => {
  const orgs = useOrganizations();
  const orgId = useOrganizationId();
  const id = props.id ?? orgId;

  // props.idを渡しており、orgsは読み込み完了していれば React.useEffectの処理をスキップ
  const [state, setState] = React.useState<State>(
    props.initialState ?? { data: !id ? undefined : (orgs ?? []).find((v) => v.id === id) }
  );

  const activate = React.useCallback(
    (org: Organization | null) => {
      const disabled = props.disabledPersistence ?? false;
      if (!disabled) {
        if (org !== null && org.org_info.use_session_login) {
          console.debug("[Firebase Auth setPersistence] Level Session");
          setPersistence(getAuth(), browserSessionPersistence);
        } else {
          console.debug("[Firebase Auth setPersistence] Level LocalStorage");
          setPersistence(getAuth(), browserLocalPersistence);
        }
      }
      setState((prev) => (prev.data === org ? prev : { ...prev, data: org ?? undefined }));
    },
    [props.disabledPersistence]
  );

  const reset = React.useCallback(() => {
    setState({ data: undefined });
  }, []);

  // `OrganizationContext` = useOrganization() が更新された際に、最新のOrganizationおよび、OrganizationInfoを取得するために利用
  // useOrganizationId() のカスタムフックの値が更新された際に、有効な企業情報を更新するために利用
  const revalidate = React.useCallback(() => {
    const org = orgs?.find((v) => v.id === id);
    activate(org ?? null);
  }, [id, orgs, activate]);

  // 初期化処理
  // 企業メンバーの場合は企業情報を事前に読み込む必要がある
  React.useEffect(() => {
    if (orgs === undefined) return; // loading parents
    if (id === undefined) return; // loading id
    if (state.data !== undefined) return; // already has loaded local state

    const isFalsyId = id === null || id === "";
    const isFalsyOrgs = orgs === null || orgs.length === 0;
    const org = orgs.find((v) => v.id === id);
    const isFalsyData = isFalsyId || isFalsyOrgs || !org;

    if (isFalsyData) {
      activate(null);
    } else {
      activate(org);
    }
  }, [activate, id, orgs, state.data, props.id]);

  // `OrganizationContext` = useOrganization() が更新された時
  // state(State) を自動更新させる
  // e.g. org.org_info.locale値の更新等
  // state(State)は `OrganizationContext` のstate値を一部冗長に持っているデータを同期させたい為
  React.useEffect(() => {
    if (orgs === undefined) return;
    revalidate();
  }, [orgs, revalidate]);

  // ログアウト時の処理
  React.useEffect(() => {
    // ログアウトすると親Contextの(OrganizationContext) の `orgs` はundefinedになる
    if (orgs === undefined) {
      setState((prev) => {
        if (prev.data === undefined) {
          // 初期値
          return prev;
        } else if (prev.data === null) {
          console.debug("ログアウト: 個人ユーザー (企業一覧がリセットされた為、選択中の企業情報をリセットします)");
          return { ...prev, data: undefined };
        } else {
          console.debug("ログアウト: 企業ユーザー (企業一覧がリセットされた為、選択中の企業情報をリセットします)");
          return { ...prev, data: undefined };
        }
      });
    }
  }, [orgs]);

  return (
    <OrganizationControlledStateContext.Provider value={state}>
      <OrganizationControlledDispatchContext.Provider value={{ activate, reset, revalidate }}>
        {props.children}
      </OrganizationControlledDispatchContext.Provider>
    </OrganizationControlledStateContext.Provider>
  );
};

export function useOrganizationControlled() {
  const ctx = React.useContext(OrganizationControlledDispatchContext);
  if (!ctx) throw new Error("undefined OrganizationControlledDispatchContext");
  return ctx;
}

export function useOrganization() {
  const state = React.useContext(OrganizationControlledStateContext);
  return state.data;
}

export function useOrganizationRole() {
  const data = useOrganization();
  return data?.role;
}

// aggregation_monthを基に何ヶ月契約をしているのかを返す
export const getOrgSubscriptionPlan = (planInfo?: TeamPlan | undefined) => {
  const months = planInfo?.aggregation_month;
  if (months === undefined) {
    return undefined;
  } else if (months === 1) {
    return "月";
  } else if (months === 12) {
    return "年";
  } else {
    return `${months}月`;
  }
};

const getOrgSupports = (org: Organization | null | undefined): Support[] => {
  if (!org || !org.org_info) {
    return [];
  }
  const { disabled_supports: disables = [], enabled_supports: enables = [] } = org.org_info;
  if (!disables) {
    return enables;
  }
  return enables.filter((v) => !disables.includes(v));
};

// 与えられたサポートをすべて満たしているか検証する
export const isSupportedAll = (org: Organization | null | undefined, targets: Support[]): boolean => {
  const supports = getOrgSupports(org);
  if (!supports) return false;
  return targets.every((target) => supports.includes(target));
};
