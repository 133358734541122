import type { Locale } from "../models/locale";

export const Constant = {
  Locale: {
    Japanese: { id: "ja-JP", text: "日本語" } as Locale,
    English: { id: "en-US", text: "英語" } as Locale,
    ID: [
      "en-US",
      "ja-JP",
      "da-DK",
      "nl-NL",
      "fi-FI",
      "fr-FR",
      "de-DE",
      "hi-IN",
      "id-ID",
      "it-IT",
      "ko-KR",
      "no-NO",
      "pl-PL",
      "pt-PT",
      "ro-RO",
      "ru-RU",
      "es-ES",
      "th-TH",
      "tr-TR",
      "uk-UA",
      "vi-VN",
      "zh",
      "zh-TW",
      "ms-MY",
    ],
  },
  Role: {
    TeamMember: {
      SuperAdmin: "super_admin",
      Admin: "admin",
      Member: "member",
      Invitation: "invitation",
    },
  },
  OAuth: {
    App: {
      /** 録画取り込み */
      Zoom: "zoom",
      ZoomPhone: "zoom_phone",
      MicrosoftTeams: "teams",
      GoogleMeet: "meet",
      /** カレンダー(今週の予定) */
      GoogleCalendar: "google-calendar",
      /** ノートとSalesforce案件紐づけ */
      Salesforce: "salesforce",
      Outlook: "outlook",
    },
    State: {
      Outlook: "schedule",
      // todo Use here instead of ZoomAuthorizeCallbackState, and delete it
      Admin: "admin", // 録画設定画面遷移用
      User: "user", // ノート一覧画面遷移用
      Apps: "apps", // アプリ設定画面遷移用
    },
  },
  // Support: {
  //   Organization: [
  //     "video",
  //     "dictionary",
  //     "team_duration_limit",
  //     "super_admin",
  //     "stop_invitation_mail",
  //     "stop_category_mail",
  //     "stop_category_mail_exclude_admin",
  //   ],
  //   Personal: [],
  // },
  Api: {
    Members: {
      BatchImport: {
        ImportMode: {
          Sync: "sync",
          Append: "append",
        },
      },
    },
  },
  User: {
    Personal: {
      // 無料枠で録音出来る上限秒数
      LimitFreeDurationSecond: 3600, // 秒
      LimitFreeDurationMinute: 60, // 分
    },
  },
  Hour: {
    Millisecond: 3_600_000,
    Second: 3600,
    Minute: 60,
  },
  Audio: {
    SampleRate: 48000,
    SampleSize: 16,
    Accept: [".mp3", ".m4a", ".wav", ".aif", ".aiff", ".flac", ".alac", ".aac", ".ogg", ".wma"] as string[],
  },
  Video: {
    Accept: [
      ".avi",
      ".mov",
      ".wmv",
      ".flv",
      ".mpg",
      ".mpeg",
      ".mp4",
      ".mkv",
      ".webm",
      ".asf",
      ".ogm",
      ".qt",
      ".m2ts",
      ".mts",
      ".ts",
    ] as string[],
  },
  Pricing: {
    TaxRate: 10, // percent
    UnitPrice: 22, // yen
    UnitPriceVideo: 33, // yen
    UnitDuration: 30, // second
  },
  Dictionary: {
    Word: {
      MaxLength: 800,
    },
  },
  Firebase: {
    Firestore: {
      Error: {
        Offline: "Failed to get document because the client is offline.",
        Connection: "Could not reach Cloud Firestore backend.",
      },
    },
  },
  Rimo: {
    Name: "Rimo合同会社",
    PostCode: "150-6018",
    Address1: "東京都 渋谷区恵比寿4丁目20番3号",
    Address2: "恵比寿ガーデンプレイスタワー18階",
    InvoiceNumber: "T8011003010416",
  },
  Paging: {
    Size: 20,
  },
  Url: {
    External: {
      Feedback: "https://docs.google.com/forms/d/e/1FAIpQLSf6JRLbYApGiiR8xIfm2Nvilur-tGYX1Eh9pVzh9OyvBCN4RQ/viewform",
    },
    Internal: {
      TopPage: "/channels",
      Calendar: "/schedule",
      Logout: "/logout",
    },
    AIEditor: {
      UnauthorizedTop: "/en/about/ai-editor/",
      Head: "/en/about/ai-editor/login/",
      Intro: "/en/about/ai-editor/intro/",
      RedirectOAuth: "/en/about/ai-editor/redirect/",
      List: "/interviews/",
      Logout: `/logout?app=ai-editor`,
    },
  },
  Regexp: {
    Mail: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  },
} as const;

export const UPLOAD_ACCEPTED_FILES = `${Constant.Audio.Accept.join(",")},${Constant.Video.Accept.join(",")}`;

export const isFirestoreOfflineError = (error: Error): boolean => {
  return error.message.startsWith(Constant.Firebase.Firestore.Error.Offline);
};
export const isFirestoreConnectionError = (error: Error): boolean => {
  return error.message.startsWith(Constant.Firebase.Firestore.Error.Connection);
};

// cf. https://github.com/rimoapp/rimo-frontend/issues/5052#issuecomment-1953348628
export const CalendarEventErrorMessage = {
  // 不明なエラーが発生しました。詳しくはサポートまでお問い合わせください。:
  unknown: {
    primary: "不明なエラーが発生したため、録画されていません。",
    secondary:
      "ミーティングの録画中に不明なエラーが発生しました。詳しくはサポートまでお問い合わせください。1日以内に記述がなければこのノートは削除します。",
    shorthand: "不明なエラー",
  },
  // 会議開始の待機時間が長すぎたため、ボットは参加できませんでした。
  timeout: {
    primary: "ミーティングが開始されず、ボットがタイムアウトしました。録画されていません。",
    secondary: "1日以内に記述がなければこのノートは削除します。",
    shorthand: "タイムアウト",
  },
  // 会議に誰も参加しなかったので録音できませんでした。
  no_joined: {
    primary: "ミーティングに誰も参加されなかったので、録画されていません。",
    secondary: "1日以内に記述がなければこのノートは削除します。",
    shorthand: "全欠席",
  },
  // 会議中の発言が確認できなかったため、録音されませんでした。
  silence: {
    primary: "ミーティング中の発言が確認できなかったため、録画されていません。",
    secondary: "1日以内に記述がなければこのノートは削除します。",
    shorthand: "発言なし",
  },
  // 会議が見つからなかったため、ボットは参加できませんでした。
  not_found: {
    primary: "ミーティングが見つからなかったため、ボットは参加できませんでした。",
    secondary:
      "もしボットを再入場させる際は、ミーティングURLを確認してください。1日以内に記述がなければこのノートは削除します。",
    shorthand: "参加失敗",
  },
  // 会議にボットが参加できませんでした
  cannot_join: {
    primary: "ミーティングにボットは参加できませんでした。",
    secondary:
      "もしボットを再入場させる際は、ボットがそのミーティングに参加可能であることを確認してください。1日以内に記述がなければこのノートは削除します。",
    shorthand: "参加失敗",
  },
  // 会議がボットが参加する前に終了していました。
  closed: {
    primary: "ボットが入る前にミーティングが終了していました。",
    secondary: "1日以内に記述がなければこのノートは削除します。",
    shorthand: "終了済み",
  },
  // 会議の録音が許可されませんでした。
  permission_denied: {
    primary: "ミーティング管理者が録画を拒否したため、文字起こしができませんでした。",
    secondary:
      "ミーティング の管理者権限をもつユーザーが録画を拒否したので議事録ボットは退出しました。議事録ボット 再入場させる場合は、管理者に録画の許諾をもらってください。",
    shorthand: "録画申請の拒否",
  },
  // 会議のホストが存在しませんでした。
  host_not_exists: {
    primary: "ミーティングのホストが存在しなかったため、録画されませんでした。",
    secondary: "1日以内に記述がなければこのノートは削除します。",
    shorthand: "ホスト不在",
  },
};
