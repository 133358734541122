import { createInstance } from "i18next";
import resourcesToBackend from "i18next-resources-to-backend";
import { initReactI18next } from "react-i18next/initReactI18next";
import { getOptions } from "./settings";
import { i18n as I18n, TFunction } from "i18next";

const initI18next = async (lng: string): Promise<I18n> => {
  const i18nInstance = createInstance();
  await i18nInstance
    .use(initReactI18next)
    .use(
      resourcesToBackend(
        (language: string, namespace: string) =>
          import(`../../../apps/frontend/public/locales/${language}/${namespace}.json`)
      )
    )
    .init({
      ...getOptions(lng),
      nsSeparator: " >> ", // {namesapce} >> {key} のように使用することで、namespaceを指定できる
      keySeparator: false,
    });
  return i18nInstance;
};

export interface TranslationResult {
  customT: TFunction;
  i18n: I18n;
}

export interface UseTranslationOptions {
  keyPrefix?: string;
}

export type Namespace = "common";

export async function useServerTranslationBase(
  lng: string,
  ns: string,
  options: UseTranslationOptions = {}
): Promise<TranslationResult> {
  const i18nextInstance = await initI18next(lng);

  const t = i18nextInstance.getFixedT(lng, ns, options.keyPrefix);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const combinedT = ((key: any) => {
    // 日本語が含まれている場合のみ変換する
    if (
      typeof key === "string" &&
      /[\u3040-\u309F\u30A0-\u30FF\u4E00-\u9FAF]/.test(key) &&
      i18nextInstance.exists(key)
    ) {
      return t(key);
    } else {
      return key;
    }
  }) as TFunction<Namespace, undefined>;

  return {
    customT: combinedT,
    i18n: i18nextInstance,
  };
}
