"use client";
import React, { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { isLanguageCode, cookieName, fallbackLng, AllLanguageCodes, appPaths } from "@rimo/i18n/settings";
import { dir } from "i18next";
import { setupLocaleAxiosRequestInterceptor } from "../api";
import { useVoiceInfo } from "./VoiceInfoContext";
import { usePathname } from "next/navigation";
import { matchesValidPath } from "../utils/matchesValidPath";

export type LocaleState = {
  locale: AllLanguageCodes;
};

type Dispatch = {
  update: (locale: string) => void;
};

const initialStateFactory = (locale: AllLanguageCodes): LocaleState => {
  return { locale: locale };
};

const LocaleContext = React.createContext<LocaleState & Dispatch>({
  locale: "ja",
  update: () => null,
});

export const LocaleProvider: React.FC<React.PropsWithChildren<{ initialLocale: AllLanguageCodes | undefined }>> = (
  props
) => {
  const [_, setCookie] = useCookies([cookieName]);
  const { children, initialLocale } = props;
  const browserLang = typeof window !== "undefined" ? langId2AvailableLangCode(navigator.language) : fallbackLng;

  const lang = initialLocale || browserLang || fallbackLng;
  const validLang = isLanguageCode(lang) ? lang : fallbackLng;
  const [state, setState] = useState<LocaleState>(initialStateFactory(validLang));
  const pathname = usePathname() ?? "";

  const update = useCallback(
    (locale: string) => {
      if (!isLanguageCode(locale)) return;

      setState((prev) => ({ ...prev, locale }));
      setCookie(cookieName, locale, { path: "/" });

      setupLocaleAxiosRequestInterceptor(locale);

      const html = document.querySelector("html");
      html?.setAttribute("lang", locale);
      html?.setAttribute("dir", dir(locale));
    },
    [setCookie]
  );

  // VoiceInfo を取得してから言語を設定すると遅いので、まず cookie の値を使い
  // VoiceInfo 取得後にcookieの値と違った場合にcookieを更新する
  const info = useVoiceInfo();
  useEffect(() => {
    if (info.isLoaded) {
      const lang = langId2AvailableLangCode(info.voiceInfo?.locale || fallbackLng);
      const pathSegments = pathname.split("/").filter(Boolean);
      const pathWithoutLng =
        pathSegments.length > 0 && isLanguageCode(pathSegments[0]) ? `${pathSegments.slice(1).join("/")}` : pathname;
      // [lng]以下のパスでない場合は言語を更新する
      if (lang !== validLang && isLanguageCode(lang) && !matchesValidPath(appPaths, pathWithoutLng)) {
        update(lang);
      }
    }
  }, [info.isLoaded, info.voiceInfo?.locale, update, validLang, pathname]);

  return <LocaleContext.Provider value={{ ...state, update }}>{children}</LocaleContext.Provider>;
};

export const useLocaleContext = () => {
  const ctx = React.useContext(LocaleContext);
  if (!ctx) throw new Error("not found LocaleContext");
  return ctx;
};

export const langId2AvailableLangCode = (_locale: string) => {
  const locale = new Intl.Locale(_locale);
  return locale.language;
};

export const getLocaleWithRegion = (locale: AllLanguageCodes) => {
  switch (locale) {
    case "ja":
      return "ja-JP";
    case "en":
      return "en-US";
    case "zh":
      return "zh";
    case "vi":
      return "vi-VN";
    default:
      return "en-US";
  }
};
