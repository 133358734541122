"use client";

import { createContext, FC, useCallback, useContext, useEffect, useMemo } from "react";

import round from "lodash/round";

import type { VoiceInfoResponse } from "../api/voice";
import { NoteEditorType } from "../utils/noteEditor";
import { UserContext } from "./UserContext";
import { useIsPersonalUser } from "../hooks/useIsPersonalUser";
import { Constant } from "../constants/constant";
import { useBackend } from "../hooks/useBackend";

type VoiceInfoContextType =
  | {
      isLoaded: false;
      voiceInfo?: undefined;
    }
  | {
      isLoaded: true;
      voiceInfo: VoiceInfoResponse;
    };

const voiceInfoContextInitialState: VoiceInfoContextType = {
  isLoaded: false,
};

const VoiceInfoContext = createContext<VoiceInfoContextType>(voiceInfoContextInitialState);

type VoiceInfoRefreshContextType = () => Promise<VoiceInfoResponse | null>;

const VoiceInfoRefreshContext = createContext<VoiceInfoRefreshContextType>(() => Promise.resolve(null));

export const VoiceInfoContextProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const {
    userState: { user },
  } = useContext(UserContext);

  const { data: voiceInfo, mutate } = useBackend<VoiceInfoResponse>(Boolean(user) && "/voice/info");

  const state: VoiceInfoContextType = useMemo(() => {
    if (!user || !voiceInfo) {
      return { isLoaded: false, voiceInfo: undefined };
    }
    return { isLoaded: true, voiceInfo };
  }, [user, voiceInfo]);

  const refresh = useCallback(async () => {
    if (!user) return null;
    const data = await mutate();
    return data ?? null;
  }, [user, mutate]);

  // ログイン・ログアウト時
  useEffect(() => {
    if (user) mutate().then().catch();
  }, [refresh, user, mutate]);

  return (
    <VoiceInfoContext.Provider value={state}>
      <VoiceInfoRefreshContext.Provider value={refresh}>{children}</VoiceInfoRefreshContext.Provider>
    </VoiceInfoContext.Provider>
  );
};

export const useVoiceInfo = (): VoiceInfoContextType & {
  refreshVoiceInfo: VoiceInfoRefreshContextType;
  editorType: NoteEditorType;
} => {
  const voiceInfo = useContext(VoiceInfoContext);
  const refresh = useContext(VoiceInfoRefreshContext);
  return {
    ...voiceInfo,
    refreshVoiceInfo: refresh,
    editorType: voiceInfo.isLoaded && voiceInfo.voiceInfo.collab_editor_enabled ? "collab" : "original",
  };
};

export const useVoiceInfoRefresh = () => {
  return useContext(VoiceInfoRefreshContext);
};

/**
 * 無料ユーザー向けの残り無料枠(秒)を返す
 */
export const useFreeDuration = (): number => {
  const { voiceInfo } = useContext(VoiceInfoContext);
  const duration = voiceInfo?.available_free_duration ?? Constant.User.Personal.LimitFreeDurationSecond;
  return duration;
};

/**
 * 無料ユーザー向けの残り無料枠(%)を返す
 */
export const useFreeProgress = (): number => {
  const duration = useFreeDuration();
  const used = Constant.User.Personal.LimitFreeDurationSecond - duration;
  const progress = round((used / Constant.User.Personal.LimitFreeDurationSecond) * 100);
  return progress;
};

/**
 * 有料ユーザーかどうか判定する
 *
 * - 企業ユーザーは必ずTrueを返す
 * - 従量課金ユーザーがクレカ登録している場合でもTrueを返す
 */
export const useUpgradedFlag = (): boolean => {
  const { voiceInfo: v } = useContext(VoiceInfoContext);
  const isPersonalUser = useIsPersonalUser();

  if (v === undefined) {
    return false;
  }
  // 例外ケース(トライアルユーザー)
  if (v.trial_user) {
    return true;
  }

  // 許可対象
  // - 企業ユーザー(通常)
  // - クレカ登録済みの従量課金ユーザー
  // - 企業ユーザー(固定)
  const isPayableUser = !isPersonalUser || v.card_registered || v.fixed_charge;

  return isPayableUser;
};

// shorthand of useVoiceInfo() React custom hooks
export const useEnableWritingModeFlag = (): boolean => {
  const { voiceInfo } = useVoiceInfo();
  const enableWritingMode = voiceInfo?.enable_writing_mode ?? false;
  return enableWritingMode;
};
