import { RimoBackendUrl } from "@rimo/frontend/constants/services";
import axios, { type AxiosError, type AxiosRequestConfig } from "axios";

export const isStorybook = process.env.STORYBOOK === "true";

export const AXIOS_INSTANCE = axios.create({
  baseURL: !isStorybook ? RimoBackendUrl : "",
  timeout: 120_000,
  headers: {
    "Content-Type": "application/json",
  },
  responseType: "json",
});

/** @public */
export const customInstance = <T>(config: AxiosRequestConfig, options?: AxiosRequestConfig): Promise<T> => {
  const promise = AXIOS_INSTANCE({
    ...config,
    params: {
      ...config.params,
    },
    ...options,
  }).then(({ data }) => data);

  return promise;
};

// In some case with react-query and swr you want to be able to override the return error type so you can also do it here like this
/** @public */
export type ErrorType<Error> = AxiosError<Error>;

/** @public */
export type BodyType<BodyData> = BodyData;
