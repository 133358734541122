"use client";

import { FC, PropsWithChildren, useContext, useEffect } from "react";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { RedirectLogin } from "./RedirectLogin";
import { UserContext } from "@rimo/frontend/contexts/UserContext";
import { Apps } from "@rimo/frontend/types/services";

// `redirected` クエリパラメータを処理し、middlewareからリダイレクトされたかどうかを判定します。
// `redirected` クエリパラメータが無い場合は、LP (`/about/voice`, `/en/about/ai-editor`) を表示します。
// `redirected` クエリパラメータがある場合は、`RedirectLogin`にてログイン情報を確認し、ログイン済みの場合はログイン後のページへ遷移します。

export const VerifyLoginRoute: FC<PropsWithChildren & { app: Apps }> = ({ children, app }) => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const router = useRouter();
  const redirected = searchParams?.get("redirected") === "true";
  const {
    userState: { user, isLoaded },
  } = useContext(UserContext);

  useEffect(() => {
    if (isLoaded && redirected && !user) {
      const params = new URLSearchParams(searchParams?.toString());
      params.delete("redirected");

      const newParams = params.toString() ? `?${params.toString()}` : "";
      const url = `${pathname}${newParams}`;
      router.replace(url);
    }
  }, [pathname, searchParams, router, user, redirected, isLoaded]);

  // redirected かつログインユーザーの場合、毎回一瞬LPが表示されてからリダイレクトされるのを防ぐため
  // SSR が効かなくなるが、直接ページにアクセスされた場合は問題ないため、ユーザー体験としては問題ない
  if (redirected && !isLoaded) return null;

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return user && redirected ? <RedirectLogin apps={app} /> : <>{children}</>;
};
